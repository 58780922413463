* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: #fff;
  color: #555;
}

a {
  opacity: 0.8;
  color: #222;
}

a:hover,
a:active {
  opacity: 1;
}

/* TimeTable */

table,
tr,
td {
  border: none;
}

table {
  border-collapse: collapse;
  font-size: 11px;
  margin: 12px auto 0;
  opacity: 0.9;
  color: rgba(0, 0, 0, 0.6);
}

td {
  padding: 8px 18px;
  text-align: center;
  vertical-align: middle;
}

tr td:first-child,
tr td:last-child {
  padding: 8px 12px;
  width: 128px;
}

tr td:first-child {
  text-align: right;
}

tr td:last-child {
  text-align: left;
}

/* Space Popover */

.space-popover-items > div:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
